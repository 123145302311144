@import "variables";
@import "mixins";

/* accordion */
.collapsible {
    border: 0;
    padding: 0;
    background: transparent;
    @include box-shadow-custom(none);
    li {
        padding: 0;
       .body-active {
           display: block;
        }
        .collapsible-body p {
            padding: 0.4rem $accordion-padding $accordion-padding $accordion-padding;
        }
        .collapsible-header {
            border-bottom: 0;
            font-weight: 600;
            padding: 10px 45px 8px $accordion-padding;
            line-height: 1.5em;
            position: relative;
            @include border-radius(6px);
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                margin-top: -1px;
                right: $accordion-padding;
                width: 0;
                height: 0;
                border: 5px solid transparent;
                border-top-color: $copy-colour;
            }
            &:hover:after {
                border-top-color: $primary-blue;
                @include transition(0.2s);
            }
            &.active:after {
                @include rotate(180deg);
                margin-top: -8px;
            }
        }
    }
}

/* step navigation start */
.step-nav {
    padding: 40px 0 0 0;
    ul {
        margin: auto;
        width: 550px;
        display: table;
        li  {
            position: relative;
            display: table-cell;
            width: 20%;
            text-align: center;
            &:after {
                width: 100%;
                z-index: 4;
                content: "";
                position: absolute;
                height: 2px;
                top: 19px;
                left: 50%;
                margin-right: 37px;
                margin-top: -1px;
                background-color: #2E3946;
                width: 100%;
            }
            &:last-child:after {
                display: none;
            }
            p {
                font-weight: 600;
                font-size: 0.85em;
                color: #2E3946;
                margin-top: 3px;
            }
            .tick {
                top: -2px;
                left: -27px;
            }
            .icon {
                position: relative;
                height: 38px;
                width: 50px;
                margin: auto;
            }
            .icon:after {
                position: absolute;
                left: 50%;
                top: 0;
                margin-left: -26px;
                content: "";
                z-index: 5;
                display: block;
                width: 52px;
                height: 38px;
                background: 50% 0 url("/static/img/user.svg") no-repeat;
            }
            &.fields .icon {
                .tick:before {
                    left: 23px;
                }
                &:after {
                    background-image: url("/static/img/fields.svg");
                    top: -3px;
                }
            }
            &.get-insight .icon {
                height: 42px;
                &:after {
                    height: 42px;
                    background-image: url("/static/img/get-insight.svg");
                }
            }
            &.pay .icon {
                .tick:before {
                    left: 21px;
                }
                &:after {
                    background-image: url("/static/img/payment.svg");
                }
            }
        }
        li:last-child .icon:before {
            display: none;
        }
        li.done .tick,
        li.active.done .tick {
            position: relative;
            display: block;
            &:before {
                left: 0;
            }
        }
    }
}
 /*step navigation end*/

/* alert box start */
.alert {
    .inner {
        @include border-radius(4px);
        padding: 8px 10px;
        background-color: $copy-colour;
        color: white;
        font-size: $small-text;
        line-height: 1em;
        img {
            margin-right: 7px;
        }
    }
    &.positive .inner {
        background-color: $primary-green;
    }
}
/* alert box end */

@media screen and (max-width: 700px) {
    .step-nav {
        ul {
            width: 100%;
        }
    }
}